<template>
    <div class="content">
        <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>

        <el-table :data='$store.state.MediaTypeDataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="mt_name" label="名称"></el-table-column>
            <el-table-column align="center" prop="mt_type" label="媒体类型">
                <template slot-scope="scope">
                    {{scope.row.mt_type===0?'社区':(scope.row.mt_type===1?'公交':(scope.row.mt_type===2?'城市路媒':(scope.row.mt_type===3?'楼宇':(scope.row.mt_type===4?'城市轨道交通':(scope.row.mt_type===5?'高速/国道':'其他')))))}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="mt_remark" label="备注"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-button @click="EditClick(scope.row)" type="primary" size="small" icon="el-icon-edit"></el-button>
                    <el-button @click="DelClick(scope.row)" type="danger" size="small" icon="el-icon-delete"></el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--弹框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width='30%' :append-to-body='true'>
            <el-form :model="mtForm" :rules="rules" ref="mtForm" label-width="100px" size='medium'>
                <el-form-item label="名称" prop="mt_name">
                    <el-input v-model="mtForm.mt_name"></el-input>
                </el-form-item>
                <el-form-item label="媒体性质">
                    <el-select v-model="mtForm.mt_type" style="width:100%;">
                      <el-option label="社区" :value='0'></el-option>
                      <el-option label="公交" :value='1'></el-option>
                      <el-option label="城市路媒" :value='2'></el-option>
                      <el-option label="楼宇" :value='3'></el-option>
                      <el-option label="城市轨道交通" :value='4'></el-option>
                      <el-option label="高速/国道" :value='5'></el-option>
                      <el-option label="其他" :value='6'></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="展现方式">
                    <el-select v-model="mtForm.mt_show_type" style="width:100%;">
                      <el-option label="灯箱广告" :value='0'></el-option>
                      <el-option label="道闸广告" :value='1'></el-option>
                      <el-option label="电梯框架" :value='2'></el-option>
                      <el-option label="广告看板" :value='3'></el-option>
                      <el-option label="屏蔽门" :value='4'></el-option>
                      <el-option label="候车亭广告" :value='5'></el-option>
                      <el-option label="车身广告" :value='6'></el-option>
                      <el-option label="其他" :value='7'></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="备注">
                    <el-input v-model="mtForm.mt_remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      MediaTypeDataList: [],
      mtForm: {
        mt_id: 0,
        mt_name: '',
        mt_type: 0,
        mt_show_type: 0,
        mt_remark: ''
      },
      dialogVisible: false,
      title: '',
      rules: {
        mt_name: [{ required: true, message: '请输入媒体类型名称', trigger: 'blur' }]
      }
    }
  },
  created () {
    if(this.$store.state.MediaTypeDataList.length===0){
        this.$store.dispatch('getMediaTypeDataList')
    }
  },
  methods: {
    AddClick () {
      this.mtForm.mt_id = 0
      this.mtForm.mt_name = ''
      this.mtForm.mt_type = 0
      this.mtForm.mt_remark = ''
      this.dialogVisible = true
      this.title = '新增'
    },
    EditClick (row) {
      this.mtForm = JSON.parse(JSON.stringify(row))
      this.title = '修改'
      this.dialogVisible = true
    },
    DelClick (row) {
      this.$confirm('确定删除媒体类型“' + row.mt_name + '”？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        util.Get('mediatype/del?id='+row.mt_id).then(res => {
          if (res.rpStatus === 10000) {  this.$store.dispatch('getMediaTypeDataList') } else { this.$message.error(res.rpMsg) }
        })
      }).catch(e => e)
    },
    submit () {
      this.$refs.mtForm.validate((valid) => {
        if (valid) {
          util.Post('mediatype/edit', this.mtForm).then(res => {
            if (res.rpStatus === 10000) {
              this.$store.dispatch('getMediaTypeDataList')
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.el-table{
    margin-top: 10px;
}
</style>
